
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorPlayersProfileNavigationVue from "@/components/administrator/players/profile/navigation.vue";
import AppAdministratorPlayersProfileStatisticsMatchesIndexVue from "@/views/app/administrator/players/profile/statistics/matches/index.vue";
import AppAdministratorPlayersProfileStatisticsTrainingsIndexVue from "@/views/app/administrator/players/profile/statistics/trainings/index.vue";
import AdministratorPlayersProfileRootUserInformationIndexVue from "@/views/app/administrator/players/profile/root/user-information/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorPlayersProfileNavigationVue,
    AdministratorPlayersProfileRootUserInformationIndexVue,
    AppAdministratorPlayersProfileStatisticsMatchesIndexVue,
    AppAdministratorPlayersProfileStatisticsTrainingsIndexVue,
  },
})
export default class AdministratorPlayersProfileStatisticIndexVue extends Vue {
}
